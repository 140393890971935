.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
      margin-left: 100px;
      margin-top: 100px;
  }

  .images-container {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      padding-bottom: 100px;
  }

  .image-box {
      position: relative;
      flex: 1 1 20%;
      height: 400px;
      overflow: hidden;
      border-radius: 10px;
      max-width: calc(25% - 10px);

      .portfolio-image {
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 80%;
          object-fit: cover;
      }

      .content {
          position: absolute;
          width: 100%;
          z-index: 3;
          padding: 10px 20px;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          background: rgb(18, 116, 155);
        //   linear-gradient(
        //       180deg,
        //       rgba(0, 0, 0, 0.2) 0,
        //       rgba(0, 0, 0, 1)
        //   );
          bottom: -70px;
      }

      .title {
          margin-bottom: 0;
          margin-top: 0;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
      }

      .description {
          font-size: 14px;
          margin-bottom: 5px;
          margin-right: 5px;
          color: #fff;
          font-weight: 700;

      }

      .btn{
          margin-top: 30px;
          margin-bottom: 10px;
          padding: 0 23px;
          height: 40px;
          line-height: 34px;
          border: 2px solid #ffd700;
          border-radius: 4px;
          font-size: 14px;
          color: #fff;
          background: transparent;
          text-transform: uppercase;
          font-weight: 700;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          cursor: pointer;
      }

      .btn:hover {
          transform: translateY(-3px);
          background: #ffd700;
      }
      .demo-btn{
        margin-top: 30px;
        margin-bottom: 10px;
        margin-left: 10px;
        padding: 0 23px;
        height: 40px;
        line-height: 34px;
        border: 2px solid #00ff5e;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        background: transparent;
        text-transform: uppercase;
        font-weight: 700;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
    }

    .demo-btn:hover {
        transform: translateY(-3px);
        background: #00ff5e;
    }

      &:after {
          content: "";
          background: linear-gradient(180deg, #00fffb, #000);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          opacity: 0;
      }

      &:hover:after {
          opacity: 0.85;
      }

      &:hover .content {
          bottom: 0;
          background: transparent;
      }
  }
}

@media screen and (max-width: 1200px) {
    .container.portfolio-page {
        width: 90%;
        padding: 20px;
        box-sizing: border-box;

        h1.page-title {
            margin-left: 20px;
            margin-top: 20px;
            text-align: center;
        }
        .description {
            display: none;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: rgb(18, 116, 155);
            // linear-gradient:(
            //     180deg,
            //     rgba(0, 0, 0, 0.2) 0,
            //     rgba(0, 0, 0, 1)
            // );

        }

        .image-box {
            height: 200px;
            min-width: calc(50% - 10px);
        }

        .btn{
            margin-top: 30px;
            margin-bottom: 10px;
            padding: 0px 5px;
            height: 30px;
            line-height: 25px;
            border: 2px solid #ffd700;
            border-radius: 4px;
            font-size: 10px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #ffd700;
        }
        .demo-btn{
          margin-top: 30px;
          margin-bottom: 10px;
          margin-left: 10px;
          padding: 0 5px;
          height: 30px;
          line-height: 25px;
          border: 2px solid #00ff5e;
          border-radius: 4px;
          font-size: 10px;
          color: #fff;
          background: transparent;
          text-transform: uppercase;
          font-weight: 700;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          cursor: pointer;
      }

      .demo-btn:hover {
          transform: translateY(-3px);
          background: #00ff5e;
      }
    }
}
