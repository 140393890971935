$primary-color: #ffd700;

@import 'animate.css';
@import '~loaders.css/src/animations/pacman.scss';
@import 'loaders.css/src/animations/ball-beat.scss';



@font-face{
  font-family: "Helvetica Neue";
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face{
  font-family: "La Belle Aurore";
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face{
  font-family: "Coolvetica";
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

.loader-active {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  animation: fadeOut 1s 1s;
  animation-fill-mode: forwards;

}

// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
